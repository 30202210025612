export const delay = (time) => new Promise ((resolve) => {setTimeout(() => {resolve(true)},time)});
export const dbFindQuery = () => ({
    "collection": "chatui_proactive_filters",
    "action": "find",
    "filter": {
        "id": window?.dynamicEngagementId
    }
});
export const extractEzivrResponse = (hoursOfOperation) => {
    // EZIVR API will return closed hours
    if(hoursOfOperation.holiday === 'yes') {
      return false;
    }
    if(hoursOfOperation.timeofday === 'yes') {
      return false;
    }
    return true;
  };
