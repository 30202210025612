import React from 'react';
import ReactDOM from 'react-dom/client';

import { datadogRum } from '@datadog/browser-rum';

import config  from './constants/config.json';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const newRoot = document.createElement('div');
newRoot.id = 'chat-studio-root';
document.body.appendChild(newRoot);
const root = ReactDOM.createRoot(newRoot);

const APPLICATION_ENV = process.env.REACT_APP_APP;

if (config?.appId && config?.cliToken) {
  datadogRum.init({
    applicationId: appId,
    clientToken: cliToken,
    site: 'datadoghq.com',
    service: '041800001bpx---DIS-DCC-Connect-ProActive-ChatUI',
    env: APPLICATION_ENV?.toUpperCase(),
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.addRumGlobalContext('barometerit', '041800001gm6');
  datadogRum.addRumGlobalContext('envlevel', APPLICATION_ENV);
}

datadogRum.startSessionReplayRecording();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
