import retry from 'async-retry';

export const request = (url, options = {}) =>
  retry(
    async () => {
      const response = await fetch(url, options);
      if (response.status === 200) return response.json();
      return null;
    },
    {
      retries: 3,
    }
  );

export default request;
