// eslint-disable max-classes-per-file
import { useState, useEffect, useReducer } from "react";
import { createPortal } from "react-dom";
import "./App.css";
import { request } from "./request";
import { extractEzivrResponse } from "./util/common.js";
import { encode as base64_encode } from "base-64";


const ARCHITECT_URI = process.env.REACT_APP_DOCDB_URI;
const IVR_URI = process.env.REACT_APP_EASY_IVR;
function App() {
  const [items, setItems] = useState([]);
  const [hideChat, setHideChat] = useState(false);
  const [hideButton, setHideButton] = useState(true);
  const [vaChatAvailable, setVaChatAvailable] = useState(!!window.vaChat);
  const [, forceUpdate] = useReducer((x) => x + 1, 1);

  const loadProactiveIcons = async () => {
    console.info(`Pro Active Connection established.✅`);
    let getVaChatname;
    if (window.va_chatname) getVaChatname = window.va_chatname;
    let getActiveSessionName = `__VirtualAssistant____IS_ACTIVE__${
      getVaChatname ? getVaChatname : ""
    }`;
    let getLocalStorageValue = sessionStorage.getItem(getActiveSessionName);
    console.debug(`DOCDB API.✅`, ARCHITECT_URI);
    console.debug(`IVR_URI API.✅`, IVR_URI);
    if (!getLocalStorageValue) {
      let tempCampaignFilters = window?.attributes;
      let dynamicEngagementId = window?.dynamicEngagementId;
      setHideButton(true);
      setHideChat(false);
      setItems("");
     console.log("dynamicEngagementId", dynamicEngagementId, "window?.dynamicEngagementId;", window?.dynamicEngagementId)
      if (dynamicEngagementId) {
        console.info(`Dynamic Engagement Id Invoked.✅`);
        let filterReqBody = {
          collection: "chatui_proactive_filters",
          action: "find",
          filter: {
            id: dynamicEngagementId,
          },
        };
        let filtersObj = "";
        let getFilteredObjects = await request(ARCHITECT_URI, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(filterReqBody),
        });
        if (getFilteredObjects?.data.length) {
          console.info(`Dynamic Engagement Id Campaign Response Recieved✅`);
          let tempFilters = getFilteredObjects?.data[0]?.filters;
          for (let i = 0; i < tempFilters.length; i++) {
            filtersObj += tempCampaignFilters[tempFilters[i]]
              .toLowerCase()
              .trim();
          }
          const encodedString = base64_encode(filtersObj);
          let proActiveReqBody = {
            collection: "chatui_dynamic_engagement",
            action: "find",
            filter: {
              search: { $regex: encodedString },
              enabled: true,
            },
          };
          let responses = await request(ARCHITECT_URI, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(proActiveReqBody),
          });
          if (responses?.data.length) {
            console.info(`Dynamic Engagement Id Base64 Response Recieved✅`);
            const exists = responses?.data.filter((element) => {
              return (
                element.hasOwnProperty("hoursEnabled") &&
                element?.hoursEnabled === true
              );
            });
            let getOnhoursStatus = false;
            if (exists.length) {
              console.info(`Dynamic Engagement Id Hours is enabled✅`);
              const ivrRequest = {
                todApp: exists[0]?.todApp,
                holidayApp: exists[0]?.holidayApp,
              };
              let ivrCall = await request(IVR_URI, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(ivrRequest),
              });
              if (ivrCall?.data) {
                getOnhoursStatus = extractEzivrResponse(ivrCall?.data);
              } else {
                console.info(`IVR API is failed`);
                console.debug(`IVR API is failed`);
              }
            } else {
              console.info(`Dynamic Engagement Id Hours is not enabled✅`);
            }
            let tempButtons = [];
            // getOnhoursStatus is true showing the button
            for (let i = 0; i < responses?.data.length; i++) {
              let buttons = responses?.data[i];
              if (buttons.hasOwnProperty("hoursEnabled")) {
                if (responses.data[i].hoursEnabled && getOnhoursStatus) {
                  console.info(`Dynamic Engagement IVR hours Enabled and loaded✅`);
                  tempButtons.push(responses.data[i]);
                } else {
                  console.info(`Dynamic Engagement IVR Out of hours✅`);
                }
              } else {
                tempButtons.push(responses.data[i]);
              }
            };
            console.info(`Dynamic Engagement ID Loaded✅`);
            setHideButton(true);
            setHideChat(true);
            setItems(tempButtons);
          } else {
            setHideChat(false);
            setItems("");
            console.info(`Dynamic_Engagement_Id_Base64_va_proactive_chat_record_not_matched`);
            console.debug(`Dynamic_Engagement_Id_Base64_va_proactive_chat_record_not_matched`);
          }
        } else {
          setHideChat(false);
          setItems("");
          console.info(`Dynamic_Engagement_Id_va_proactive_chat_record_not_matched`);
          console.debug(`Dynamic_Engagement_Id_va_proactive_chat_record_not_matched`);
        }
      } else {
        console.info(`Include URL Campaign Invoked.✅`);
        let reqBody = {
          collection: "chatui_dynamic_button",
          action: "find",
          filter: {
            engagementId: window?.engagementId,
            contactFlowId: window?.contactFlowId,
          },
        };
        const response = await request(ARCHITECT_URI, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reqBody),
        });
        if (response?.data.length) {
          console.info(`Include URL Campaign Response Recieved✅`);
          const exists = response?.data.filter((element) => {
            return (
              element.hasOwnProperty("hoursEnabled") &&
              element?.hoursEnabled === true
            );
          });
          let getOnhoursStatus = false;
          if (exists.length) {
            console.info(`Include URL Campaign Hours is enabled✅`);
            const ivrRequest = {
              todApp: exists[0]?.todApp,
              holidayApp: exists[0]?.holidayApp,
            };
            let ivrCall = await request(IVR_URI, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(ivrRequest),
            });
            getOnhoursStatus = extractEzivrResponse(ivrCall.data);
          }
          let tempButtons = [];
          for (let k = 0; k < response?.data.length; k++) {
            let tempCampaignData = response?.data[k]?.campaignFilter;
            let tempCampaignFilters = window?.campaignFilter;
            let tempObj = tempCampaignData[0];
            let count = 0;
            for (var i in tempObj) {
              for (var j in tempCampaignFilters) {
                if (i === j && tempObj[i] === tempCampaignFilters[i]) {
                  count++;
                }
              }
            }
            let tempIncludeUrl = response?.data[k]?.includeurl || [];
            let tempExcludeUrl = response?.data[k]?.excludeurl || [];

            const url = new URL(window.location.href.toString());
            const rootUrl = `${url.protocol}//${url.hostname}${
              url.port ? `:${url.port}` : ""
            }`;
            const filterIncludeArray = tempIncludeUrl.filter(
              (element) => element === `${rootUrl}/*`
            );

            const filterExcludeArray = tempExcludeUrl.filter(
              (element) => element === `${rootUrl}/*`
            );

            if (
              count === tempCampaignData.length &&
              response?.data[k]?.enabled
            ) {
              if (
                (tempIncludeUrl.includes(window.location.href.toString()) ||
                  filterIncludeArray.length > 0) &&
                !tempExcludeUrl.includes(window.location.href.toString()) &&
                filterExcludeArray.length <= 0 &&
                response?.data[k]?.enabled
              ) {
                console.info(`Include URL Condition Matched✅`);
                let tempDetails = response?.data[k]?.delay;
                let tempDelay = tempDetails.filter(
                  (ele) => ele.url === window.location.href
                );
                if (tempDelay.length) {
                  // await delay(
                  //   tempDelay?.[0]?.delay ? tempDelay?.[0]?.delay : 1000
                  // );
                  let stringValues = JSON.stringify(response.data[k]);
                  let parsedValue = JSON.parse(stringValues);
                  parsedValue.delay = tempDelay?.[0]?.delay
                    ? tempDelay?.[0]?.delay
                    : 0;
                  if (response.data[k].hasOwnProperty("hoursEnabled")) {
                    if (response.data[k].hoursEnabled && getOnhoursStatus) {
                      tempButtons.push(parsedValue);
                    }
                  } else {
                    tempButtons.push(parsedValue);
                  }
                } else {
                  tempButtons.push(response.data[k]);
                }
              } else {
                setHideChat(false);
                setItems("");
                console.info(`Include URL va_proactive_chat_condition_failed`);
                console.debug(`Include URL va_proactive_chat_condition_failed`);
              }
            } else {
              setHideChat(false);
              setItems("");
              console.info(`Include URL va_proactive_chat_condition_failed`);
              console.debug(`Include URL va_proactive_chat_condition_failed`);
            }
          };
          console.info(`Include URL Loaded✅`);
          setHideButton(true);
          setHideChat(true);
          setItems(tempButtons);
        } else {
          console.info(`va_proactive_chat_record_not_matched`);
          console.debug(`va_proactive_chat_record_not_matched`);
        }
      }
      // forcefully re render
      forceUpdate();
    } else {
      console.info(`Chat widget is currently active`);
      console.debug(`Chat widget is currently active`);
    }
  };

  const LoadButtonComponent = ({ body, indexValue, delay }) => {
    const [rendered, setRendered] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        setRendered(true);
      const updateEvent = new CustomEvent("proactiveIconAppeared", {
      detail: {
        message: "Proactive icon is loaded",
        data: body,
        attributes: window?.attributes
      },
      bubbles: true,
      cancelable: true,
    });
    if (window.vaChat || vaChatAvailable) {
      document.dispatchEvent(updateEvent);
    }
      }, delay || 0);
    });

    return (
      rendered && (
        <div
          key={indexValue}
          data-testid={`proactive_button${indexValue}`}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      )
    );
  };

  function handleConnectChatLoad() {
    setVaChatAvailable(true); // update state. trigger useEffect connectWidgetLoaded dependency
  }

  useEffect(() => {
    // getChatIcon();
    //  loadProactiveIcons();
    /* eslint-disable-next-line */
  }, [location]);

  useEffect(() => {
    document.addEventListener("vaWidgetLoaded", handleConnectChatLoad);
    window.loadProactiveIcons = loadProactiveIcons;
    console.info(`va_proactive_chat_proactive_subscribe`);
    console.debug(`va_proactive_chat_proactive_subscribe`);
    const updateEvent = new CustomEvent("proactiveLoader", {
      detail: {
        message: "proactive has been loaded",
      },
      bubbles: true,
      cancelable: true,
    });
    if (window.vaChat || vaChatAvailable) {
      document.dispatchEvent(updateEvent);
    }
    return () => {
      document.removeEventListener("vaWidgetLoaded", handleConnectChatLoad);
    };
  }, [vaChatAvailable]);
  
  // eslint-disable-next-line
  window.openWidget = function (data) {
    /* eslint-disable-next-line */
    if (data)
      sessionStorage.setItem(
        "__VirtualAssistant__Button_Name__Proactive",
        data
      );
    /* eslint-disable-next-line */
    if (vaChat) {
      setTimeout(() => {
        /* eslint-disable-next-line */
        vaChat.open();
      }, 1000);
    }
  };
  // eslint-disable-next-line
  window.hideChatButton = function () {
    /* eslint-disable-next-line */
    setHideButton(false);
    setItems("");
  };

  const renderTarget = document.getElementById("va_chat");
  if (renderTarget && !document.getElementById("va_triggerTarget")) {
    const triggerTarget = document.createElement("div");
    triggerTarget.id = "va_triggerTarget";
    renderTarget.appendChild(triggerTarget);
  }
  const renderTriggerTarget = document.getElementById("va_triggerTarget");
  return (
    <>
      {hideButton && hideChat && renderTriggerTarget
        ? createPortal(
            items.map((element, index) => {
              return (
                <>
                  <LoadButtonComponent
                    body={element?.body}
                    indexValue={index}
                    delay={element?.delay ? element.delay : 0}
                  />
                </>
              );
            }),
            renderTriggerTarget
          )
        : ""}
    </>
  );
}

export default App;
